<template>
  <div style="display: flex">
    <contract-side-menu active-index="4"></contract-side-menu>
  <el-container>
    <el-header style="position: fixed;width: 100%;z-index: 100;background: white;height: 50px;">
      <Header>
      </Header>
    </el-header>
    <el-container style="position: absolute; top: 60px;">
      <el-table
          :data="templates"
          style="width: 100%">
        <el-table-column
            prop="publishTemplate.title"
            label="标题"
            width="150">
        </el-table-column>
        <el-table-column
            prop="publishTemplate.categoryName"
            label="类别"
            width="120">
        </el-table-column>
        <el-table-column
            label="状态"
            width="120">
            <template #default="scope">
              <span v-if="scope.row['publishTemplate'] && scope.row['publishTemplate']['approvalStatus'] === 1" style="margin-left: 10px">已审批</span>
              <span v-else style="margin-left: 10px">待审批</span>
            </template>
        </el-table-column>
        <el-table-column
            prop="relatedUsers"
            label="可见组织及人员"
            width="120">
        </el-table-column>
        <el-table-column
            prop="publishTemplate.creatorName"
            label="创建人"
            width="120">
        </el-table-column>
        <el-table-column
            prop="publishTemplate.createTime"
            label="创建时间"
            width="120">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="120">
          <template #default="scope">
            <el-button
                @click.prevent="changeTemplatePublishSetting(scope, scope.$index)"
                type="text"
                size="small">
              修改设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-container>
  </el-container>
  </div>
</template>

<script>
import Header from "../components/Header";
import {ElLoading, ElMessage} from 'element-plus';
import config from "../config";
import { getTemplates } from "../api/api";
import ContractSideMenu from "../components/ContractSideMenu";

export default {
  name: "TemplateApprovalList",
  data: function () {
    return {
      templates: []
    };
  },
  mounted: function () {
    getTemplates().then(res => {
      console.log('getTemplates  res === ', res);
      this.templates = res.data.data;
    }).catch(err => {
      console.log('getTemplates  err === ', err);
      ElMessage({
        showClose: true,
        message: "获取模板列表失败",
        type: 'error'
      });
    })
  },
  methods: {
    changeTemplatePublishSetting: function (scope, index) {
      this.$router.push({
        name:'templatePublish',
        query:{
          templateId: scope.row.publishTemplate.id
        }
      })
    },
  },
  components: {ContractSideMenu, Header}
}
</script>

<style scoped lang="scss">
</style>
